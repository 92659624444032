// Formulaire
export const validate = function(e) {
    var focus = "nom";
    var fields = document.querySelectorAll('.form-container textarea, .form-container input[type="text"], .form-container input[type="email"]');
    var regEx;
    var removeSpan;
    var par;
    var check = false;
    var val;
    var errArr = [];
    for (var i = 0; i < fields.length; i++) {
        if (fields[i].value === "") {
            if (fields[i].nextElementSibling.classList.contains('error')) {
              removeSpan = fields[i].nextElementSibling;
              par = fields[i].parentNode;
              par.removeChild(removeSpan);
              fields[i].nextElementSibling.innerHTML = "Le champ " + fields[i].placeholder + " est requi.";
              fields[i].style.boxShadow = "0 0 2px 1px #cc0001";
              check = false;
              errArr.push(fields[i]);
              
            }
            fields[i].nextElementSibling.innerHTML = "Le champ " + fields[i].placeholder + " est requi.";
            fields[i].style.boxShadow = "0 0 2px 1px #cc0001";
            check = false;
            errArr.push(fields[i]);
            
        } else {

          

            if (fields[i].id === 'mail') {
                val = isValidEmail(fields[i]);
                if(val === false) {
                    fields[i].nextElementSibling.innerHTML = "Cette adresse email semble invalide";
                    fields[i].style.boxShadow = "0 0 2px 1px #cc0001";
                    check = false;
                    errArr.push(fields[i]);
                } else {
                    fields[i].nextElementSibling.innerHTML = "";
                    fields[i].style.boxShadow = "none";
                    check = true;
                }
            }
        }
        
    }
  
    if(check === false) {
      var count = 0;
      var toErr = setInterval(function() {
        var e = errArr[0].offsetTop + -25;
        var pos = Math.abs(e);
        if(count < pos) {
          count ++;
        } else {
          clearInterval(toErr);
        }
      }, 1);
    }
    
    return check

    // Helper functions.
    function isValidEmail(e) {
        regEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var email = e.value;
        if (!regEx.test(email)) {
            return false;
        }
    }

    function isValidChar(e) {
        regEx = /^[a-zA-Z@#$%!?^&*()_+\-=\[\]{};':"\\|,.\/? ]*$/;
        var value = e.value;
        if (!regEx.test(value)) {
            return false;
        }
    }

};
import React from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

import bg from '../images/BG-quoideneuf_topright2.svg';
import reclam1 from '../styles/reclamation/images/Reclamation-1.png';
import reclam2 from '../styles/reclamation/images/Contact_2-688x1024.jpg';

import { Layout } from '../components/organisms';
import { Footer } from '../components/molecules';
import { PageWrapper } from '../components/atoms';
import { dimensions } from '../styles/variables';
import { validate } from '../customJS/reclamation/reclamation';

import '../styles/reclamation/css/reclamation.css';

const Content = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 72px;
  padding-bottom: ${dimensions.space.fourtyEight};
  flex-grow: 1;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      lastName: '',
      firstName: '',
      sender: 'service.reclamation@sgsante.fr',
      to: 'service.reclamation@looma-assurances.fr',
      subject: 'Réclamation du site www.looma-assurances.fr',
      message: '',
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    validate();
    const arr = [];
    arr.push(this.state);

    const test = await fetch('https://rec.looma-assurances.fr/token')
      .then((response) => response.json())
      .then((data) =>
        axios({
          method: 'POST',
          url: 'https://api-communication-sortante.sgsante.fr/api/sendMail',
          data: arr,
          headers: { Authorization: `Bearer ${data}` },
        })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              alert('Merci, votre message a été envoyé.');
              this.resetForm();
            } else if (response.data.status === 'fail') {
              alert('Une erreur est survenue.');
            }
          })
          .catch(() => {
            alert('Une erreur est survenue.');
          }),
      );
  }

  resetForm() {
    this.setState({ firstName: '', lastName: '', from: '', mail: '', message: '' });
  }

  render() {
    return (
      <Layout>
        <Content>
          <PageWrapper>
            <div id="bienvenue">
              <div className="reclamationbloc1">
                <img src={reclam1} alt="gestionnaire" />
                <div className="reclamTitre">
                  <h1>Réclamation</h1>
                  <p>
                    Nous avons à cœur d’apporter la meilleure qualité de service qui soit. Cependant, certaines
                    insatisfactions peuvent subsister. Ainsi, vous avez la possibilité de nous faire part de votre
                    insatisfaction, par le biais d’une réclamation.
                  </p>
                </div>
              </div>
            </div>

            {/*  Accordeon */}

            <div className="col accordeon">
              <div className="tabs">
                <div className="tab">
                  <input className="inputAccordeon" type="radio" id="rd1" name="rd" />
                  <label className="tab-label" htmlFor="rd1">
                    Qu’est-ce qu’une réclamation ?
                  </label>
                  <div className="tab-content">
                    <p>
                      <span>
                        Notre Autorité de Tutelle, l’Autorité de contrôle Prudentiel, indique qu’une réclamation est
                        l’expression de tout mécontentement, ou insatisfaction.
                      </span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>Dès lors, peut être qualifiée de réclamation : </span>
                      <span>
                        l’absence ou le délai de traitement d’une demande de résiliation/clôture, la qualité d’accueil,
                        l’absence ou le délai d’exécution d’une opération ou de versement de prestations.
                      </span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>À savoir : </span>
                      <span>ne constitue pas une réclamation : une simple demande de renseignement ou d’avis.</span>
                    </p>
                  </div>
                </div>
                <div className="tab">
                  <input className="inputAccordeon" type="radio" id="rd2" name="rd" />
                  <label className="tab-label" htmlFor="rd2">
                    Qui peut formuler cette réclamation ?{' '}
                  </label>
                  <div className="tab-content">
                    <p>
                      <span>En tant que client, vous disposez de ce droit.</span>
                      <br />
                      <span>
                        Par ailleurs, l’Autorité de Contrôle Prudentiel élargit la définition du réclamant à toute
                        personne qui le souhaite, et ce même en l’absence d’une relation contractuelle avec le
                        professionnel.
                      </span>
                      <br />
                      <span>
                        De ce fait, un réclamant, peut être un tiers, un ancien client, un bénéficiaire, un ayant droit…
                      </span>
                      <br />
                      <span>
                        Le réclamant peut également être un média, une autorité administrative, un membre d’une
                        profession juridique ou une association de défense des consommateurs qui peut agir pour le
                        compte de l’assuré, ou pour dénoncer certaines pratiques ou situations.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="tab">
                  <input className="inputAccordeon" type="radio" id="rd3" name="rd" />
                  <label className="tab-label" htmlFor="rd3">
                    Comment procéder ?
                  </label>
                  <div className="tab-content">
                    <p>
                      <span>Un recours préalable :</span>
                      <br />
                      <span>Vous disposez de plusieurs moyens de formulation de votre demande :</span>
                      <ul>
                        <li>
                          Par e-mail :
                          <a href="mailto:service.reclamation@looma-assurances.fr">
                            <i> service.reclamation@looma-assurances.fr</i>
                          </a>
                        </li>
                        <li>
                          En remplissant notre <a href="#formulaire-reclamation">formulaire en bas de page</a>
                        </li>
                        <li>
                          Par courrier : LOOMA{' '}
                          <i>(Service réclamation – 14 rue Joliot Curie CS30248 51010 CHALONS-EN-CHAMPAGNE CEDEX)</i>
                        </li>
                      </ul>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>En cas de désaccord</span>
                      <span>
                        {' '}
                        persistant avec la réponse apportée à votre réclamation, vous pouvez recourir à la médiation
                        compétente :
                      </span>
                      <br />
                      <br />
                      <span style={{ fontWeight: 'bold' }}>
                        A noter que le Médiateur compétent dépend de l’assureur mentionné sur vos documents d’assurance.
                      </span>
                      <br />
                      <span>Peuvent donc être compétents, les médiateurs suivants :</span>
                      <ul>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>LA MEDIATION DE L’ASSURANCE</span>
                          <br />
                          <i>
                            {' '}
                            TSA 50110 &#8211; 75441 Paris Cedex 09 &#8211;{' '}
                            <a href="https://www.mediation-assurance.org/">https://www.mediation-assurance.org/</a>
                          </i>
                        </li>
                      </ul>
                      <p>
                        La liste des assureurs assujettis est disponible sur{' '}
                        <a href="https://formulaire.mediation-assurance.org/Societes">
                          https://formulaire.mediation-assurance.org/Societes
                        </a>
                      </p>
                      <ul>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>CTIP – LE MEDIATEUR DE LA PROTETION SOCIALE</span>
                          <br />
                          <i>
                            {' '}
                            10 rue Cambacérès &#8211; 75008 PARIS &#8211;{' '}
                            <a href="https://ctip.asso.fr/mediateur-de-la-protection-sociale-ctip/">
                              https://ctip.asso.fr/mediateur-de-la-protection-sociale-ctip/
                            </a>
                          </i>
                        </li>
                      </ul>
                      <p>
                        La liste des assureurs assujettis est disponible sur{' '}
                        <a href="https://ctip.asso.fr/mediateur-de-la-protection-sociale-ctip/">
                          https://ctip.asso.fr/mediateur-de-la-protection-sociale-ctip/
                        </a>
                      </p>
                      <ul>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>FNMF – LE MEDIATEUR DE LA MUTUALITE FRANCAISE</span>
                          <br />
                          <i>
                            {' '}
                            255 rue de Vaugirard &#8211; 75719 PARIS cedex 15 &#8211;{' '}
                            <a href="https://saisine.mediateur-mutualite.fr/">
                              https://saisine.mediateur-mutualite.fr/
                            </a>
                          </i>
                        </li>
                      </ul>
                      <p>
                        La liste des assureurs assujettis est disponible sur{' '}
                        <a href="https://saisine.mediateur-mutualite.fr/">https://saisine.mediateur-mutualite.fr/</a>
                      </p>
                      <ul>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>
                            CNPM – Médiateur de la Chambre Nationale des Praticiens de la Médiation
                          </span>
                          <br />
                          <i>
                            {' '}
                            Médiation Consommation &#8211; 75719 PARIS cedex 15 &#8211;{' '}
                            <a href="https://www.cnpm-mediation-consommation.eu/">
                              https://www.cnpm-mediation-consommation.eu/
                            </a>
                          </i>
                        </li>
                      </ul>
                      <p>
                        Ce médiateur est compétent pour des assureurs tels qu’HARMONIE MUTUELLE, MGEN IB, MGEN
                        SOLUTIONS, MUTUALIA ALLIANCE SANTE, MUTUALIA TERRITOIRES SOLIDAIRES ou encore SMATIS.
                      </p>
                      <span>
                        Vous pouvez les saisir par courrier aux adresses précitées ou via le formulaire en ligne.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="tab">
                  <input className="inputAccordeon" type="radio" id="rd4" name="rd" />
                  <label className="tab-label" htmlFor="rd4">
                    Quels sont les délais de réponse ?
                  </label>
                  <div className="tab-content">
                    <p>
                      <span>Les délais maximum de traitement sont de :</span>
                      <ul>
                        <li>10 jours ouvrables</li>
                      </ul>
                      <span>
                        A compter de la réception de la réclamation, pour en accuser réception, sauf si la réponse
                        elle-même est apportée au client dans ce délai.
                      </span>
                      <ul>
                        <li>En tout état de cause : 2 mois :</li>
                      </ul>
                      <span>
                        Entre la date de réception de la réclamation et la date d’envoi de la réponse définitive
                        apportée au réclamant.
                      </span>
                      <ul>
                        <li>Dans le cadre d’un recours auprès du Médiateur de l’Assurance :</li>
                      </ul>
                      <span>Celui-ci apporte un avis dans les 3 mois à compter réception du dossier complet.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*  Formulaire */}

            <section id="formulaire-reclamation" className="formulaire">
              <div className="formtitre">
                <h1>Formulaire de réclamation</h1>
              </div>
              <div className="formu">
                <div className="formuImage">
                  <img className="formuImage2" src={reclam2} alt="contact" />
                </div>

                <div className="form-container">
                  <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="formubutton">
                      {/*  <label htmlFor="name">Name</label> */}
                      <input
                        id="nom"
                        name="firstname"
                        placeholder="Nom"
                        type="text"
                        className="ReclamForm-control"
                        value={this.state.firstName}
                        onChange={this.onFirstnameChange.bind(this)}
                        required
                      />
                      <span className="errors" />
                    </div>
                    <div className="formubutton">
                      <input
                        id="prenom"
                        name="lastname"
                        placeholder="Prénom"
                        type="text"
                        className="ReclamForm-control"
                        value={this.state.lastName}
                        onChange={this.onLastnameChange.bind(this)}
                        required
                      />
                      <span className="errors" />
                    </div>
                    <div className="formubutton">
                      <input
                        id="mail"
                        name="mail"
                        placeholder="Email"
                        type="email"
                        className="ReclamForm-control"
                        aria-describedby="emailHelp"
                        value={this.state.mail}
                        onChange={this.onEmailChange.bind(this)}
                        required
                      />
                      <span className="errors" />
                    </div>
                    <div className="formubutton">
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        className="ReclamForm-control"
                        rows="5"
                        value={this.state.message}
                        onChange={this.onMessageChange.bind(this)}
                        required
                      />
                      <span className="errors" />
                    </div>
                    <button type="submit" className="reclamFormButton" name="envoyer">
                      Envoyer
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </PageWrapper>
        </Content>
        <Footer />
      </Layout>
    );
  }

  onFirstnameChange(event) {
    this.setState({ firstName: event.target.value });
  }

  onLastnameChange(event) {
    this.setState({ lastName: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ from: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
}

<script src="" />;

export default App;
